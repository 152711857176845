<template>
  <div>
    <div v-if="loading" class="text-center">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </div>
    <v-container v-else grid-list-xl fluid>
      <v-layout wrap>
        <v-flex sm12>
          <v-widget :title="$t('message.notifications')" content-bg="white">
            <div slot="widget-content">
              <v-alert v-if="no_notificaciones" color="light-blue" icon="mdi-bell-off" :value="true">
                {{ $t('notificaciones.no_notis') }}
              </v-alert>
              <template v-for="(item, index) in notis">
                <v-list-item :key="index" :class="item.today !== '' ? 'noty_hoy' : ''" @click="goToNotificacion(item)">
                  <v-list-item-avatar :color="item.color">
                    <v-icon dark>{{ item.icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.date" />
                    <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="caption">
                    {{ item.timeLabel }}
                  </v-list-item-action>
                </v-list-item>
              </template>
            </div>
          </v-widget>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { getNotificacionesUrl, getHeader } from '../config/config';
import VWidget from '@/components/VWidget.vue';

export default {
  components: { VWidget },
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: true,
    snackbar: false,
    no_notificaciones: true,
    timeout: 5000,
    mode: '',
    alerta: {
      texto: '',
      icon: '',
      color: 'info',
      tipo: 'outlined',
    },
    notis: [],
    disabledButton: false,
  }),

  created() {
    this.$http.get(getNotificacionesUrl + '?todas=1', { headers: getHeader() }).then(response => {
      this.notis = response.data;
      if (this.notis.length > 0) {
        this.no_notificaciones = false;
      }
      this.loading = false;
    });
  },

  methods: {
    goToNotificacion(item) {
      if (item.icon === 'mdi-message-text') {
        this.$router.push('/mensajeria/' + item.id);
      } else if (item.icon === 'mdi-text-box-plus') {
        this.$router.push('/prescriptions');
      } else {
        this.$router.push('/videoconsulta');
      }
    },
  },
};
</script>
<style scoped lang="css">
.es_hoy {
  font-weight: bold;
  color: #ff5722;
}

.noty_hoy {
  background: #f7e0e6;
}

.noty_hoy:hover {
  background: rgba(152, 202, 241, 0);
}

.noty:hover {
  background: rgba(152, 202, 241, 0.31);
  cursor: pointer;
}

.dot_noty:hover {
  width: 12px;
  height: 12px;
  cursor: pointer;
}
</style>
